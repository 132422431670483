import React, { useState } from 'react';
import { ArrowDown, Check } from 'lucide-react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Hero = () => {
  const [isDragging, setIsDragging] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: !isDragging,
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const scrollToForm = () => {
    const formSection = document.getElementById('form-section');
    formSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative bg-gradient-to-br from-yellow-50 to-blue-100 min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
      <div className="mt-16 absolute inset-0">
        <img
          src="/assets/DSC06140.jpg"
          alt="Yoga and Mindfulness"
          className="w-full h-full object-cover opacity-30"
        />
      </div>
      <div className="relative max-w-7xl mx-auto w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight">
              <span className="block text-3xl">Descubre</span>
              <span className="block text-3xl">Tu Nivel de</span>
              <span className="block text-amber-700">Salud Mental</span>
            </h1>
            <p className="text-xl text-gray-700">
              Este sencillo test de 10 preguntas<br />
              te ayudará a conocerte mejor <br />
              y a identificar qué tipo de apoyo <br />
              podrías necesitar en este momento
            </p>
            <ul className="space-y-4">
              {['Menos de 3 minutos', 'Resultados inmediatos', 'Regalo personalizado según resultados'].map((item, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <Check className="h-5 w-5 text-green-500" />
                  <span className="text-gray-700 font-bold">{item}</span>
                </li>
              ))}
            </ul>

            <div
              className="hero-slider"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <Slider {...settings}>
                <div>
                  <img src="assets/reviews/1.png" alt="Descripción de la imagen" className="mx-auto my-4" />
                </div>
                <div>
                  <img src="assets/reviews/2.png" alt="Otra descripción" className="mx-auto my-4" />
                </div>
                <div>
                  <img src="assets/reviews/3.png" alt="Otra descripción" className="mx-auto my-4" />
                </div>
              </Slider>
            </div>

            <div className="flex space-x-4">
              <button
                onClick={scrollToForm}
                className="px-6 py-3 text-lg font-medium rounded-full text-white bg-amber-700 hover:bg-amber-600 transition-colors duration-300 ease-in-out shadow-lg hover:shadow-xl flex items-center"
              >
                Comenzar Test
                <ArrowDown className="ml-2 h-5 w-5" />
              </button>
              <Link to="/about" >
                <button className="px-6 py-3 text-lg font-medium rounded-full text-amber-700 bg-white hover:bg-gray-100 transition-colors duration-300 ease-in-out shadow-lg hover:shadow-xl border border-blue-600">
                  Saber más
                </button>
              </Link>
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 rounded-full filter blur-3xl opacity-20 animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
